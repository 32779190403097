import {
  Button,
  FormAdapter,
  FormChildrenMethods,
  FormInput,
  Panel,
  Price,
  StatusChip,
  StatusFlavour,
  StatusText,
} from "@hotelspoint/components";
import { useUserAgencyStore, useUserCurrencyStore } from "@hotelspoint/store";
import {
  Hotel,
  OfferGroup,
  OfferItem,
  RateType,
  SearchHotelsResultsModalState,
  SearchHotelsResultsModalType,
} from "@hotelspoint/types";
import { daysBetween, formatDate } from "@hotelspoint/utils";
import { IconUser } from "@tabler/icons-react";
import groupBy from "lodash/groupBy";
import qs from "query-string";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// @todo: bad import
import HotelDetailsGalleryModal from "../../../Search/SearchHotels/SearchHotelsResults/HotelDetailsModal/HotelDetailsGalleryModal";
import { FormValues } from "../ProfileOfferForm/ProfileOfferForm.util";
import ProfileOfferHotelDetails from "./ProfileOfferHotelDetails";
import * as S from "./ProfileOfferPanel.styled";
import ProfileOfferPanelDeleteRate from "./ProfileOfferPanelDeleteRate";

interface ProfileOfferPanelProps extends FormChildrenMethods<FormValues> {
  offerId: number;
  offerItems: OfferItem[];
}

const ProfileOfferPanel = ({ offerId, offerItems }: ProfileOfferPanelProps) => {
  const { t } = useTranslation();

  const [activeModal, setActiveModal] = useState<
    SearchHotelsResultsModalState | undefined
  >(undefined);
  const [activeHotel, setActiveHotel] = useState<Hotel | undefined>(undefined);
  const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

  const currency = useUserCurrencyStore(state => state.currency);
  const agency = useUserAgencyStore(state => state.agency);

  const groupedOffers = useMemo(() => {
    if (!offerItems) return [];

    const groupedByItemId = groupBy(offerItems, "itemId");

    const resultArray = Object.entries(groupedByItemId).map(
      ([itemId, items]) => {
        const groupedByCheckInOut = groupBy(items, hotel => {
          const { checkIn, checkOut } = hotel.itemData.search;
          return `${checkIn}:${checkOut}`;
        });

        const groupedItemsArray = Object.entries(groupedByCheckInOut).map(
          ([checkInOut, items]) => {
            const [checkIn, checkOut] = checkInOut.split(":");
            return {
              checkIn,
              checkOut,
              items,
            };
          },
        );

        return {
          itemId,
          groupedItems: groupedItemsArray,
        };
      },
    );

    return resultArray;
  }, [offerItems]);

  const handleShowDetails = (hotel: Hotel) => {
    // @todo: why are we setting the whole hotel object instead of just the id?
    setActiveHotel(hotel);
    setActiveModal({
      id: hotel.id,
      type: SearchHotelsResultsModalType.Details,
    });
  };

  const getSearchParams = useCallback(
    (dateOffer: OfferGroup, offerItem: OfferItem) => {
      const room = offerItem.itemData.rooms.map(room => {
        const { adults, children } = room;

        return {
          adults,
          children: children.length === 0 ? [] : children,
        };
      });

      return qs.stringify({
        checkIn: dateOffer.checkIn,
        checkOut: dateOffer.checkOut,
        nationality: `${agency?.countryCode}`.toLowerCase(),
        rooms: JSON.stringify(room),
      });
    },
    [agency?.countryCode],
  );

  return (
    <>
      <HotelDetailsGalleryModal
        hotel={activeHotel}
        isLoading={false}
        activeModal={activeModal}
        initialGalleryIndex={initialGalleryIndex}
        setInitialGalleryIndex={setInitialGalleryIndex}
        setActiveModal={setActiveModal}
      />
      {groupedOffers.map((offerGroup, offerGroupIndex) => (
        <div key={offerGroup.itemId} style={{ marginBottom: 32 }}>
          <Panel title={`${t("profileOffer.offer")} ${offerGroupIndex + 1}`}>
            <ProfileOfferHotelDetails
              id={Number(offerGroup.itemId)}
              setActiveHotel={handleShowDetails}
            />
            {offerGroup.groupedItems.map((offerGroup, offerGroupIndex) => (
              <div key={offerGroupIndex}>
                <S.StayWrapper>
                  <S.DatesWrapper>
                    <S.SingleDateWrapper>
                      <S.Heading>{t("profileOffer.dates.checkIn")}</S.Heading>
                      <S.BoldHeadline>
                        {formatDate(offerGroup.checkIn as string)}
                      </S.BoldHeadline>
                    </S.SingleDateWrapper>
                    <div>
                      <S.Divider />
                    </div>
                    <S.SingleDateWrapper>
                      <S.Heading>{t("profileOffer.dates.checkOut")}</S.Heading>
                      <S.BoldHeadline>
                        {formatDate(offerGroup.checkOut as string)}
                      </S.BoldHeadline>
                    </S.SingleDateWrapper>
                  </S.DatesWrapper>
                  <S.NightsWrapper>
                    <p>{t("profileOffer.dates.lengthOfStay")}</p>
                    <StatusChip>
                      <S.Strong>
                        {t("profileOffer.dates.night", {
                          count: daysBetween({
                            to: offerGroup.checkOut,
                            from: offerGroup.checkIn,
                          }),
                        })}
                      </S.Strong>
                    </StatusChip>
                  </S.NightsWrapper>
                </S.StayWrapper>
                {offerGroup.items.map(offerItem => (
                  <S.RatesWrapper key={offerItem.id}>
                    <S.THeadWrapper>
                      <S.NameCell>
                        <S.Heading>{t("profileOffer.room")}</S.Heading>
                      </S.NameCell>
                      <S.PersonsCell />
                      <S.PricePolicyCell>
                        <S.Heading>{t("profileOffer.netPrice")}</S.Heading>
                      </S.PricePolicyCell>
                      <S.ActionsCell>
                        <S.Heading>{t("profileOffer.clientPrice")}</S.Heading>
                      </S.ActionsCell>
                    </S.THeadWrapper>
                    {offerItem.itemData.rooms.map((room, roomIndex) => (
                      <S.RoomWrapper key={roomIndex}>
                        <S.NameCell>
                          <S.TabletHeading>
                            {t("profileOffer.room")}
                          </S.TabletHeading>
                          <S.BoldHeadline>
                            {[room.numRooms, "x", room.roomName].join(" ")}
                          </S.BoldHeadline>
                        </S.NameCell>
                        <S.PersonsCell>
                          <S.PersonsWrapper>
                            {Array.from({ length: room.adults }).map(
                              (_, index) => (
                                <IconUser key={index} size={18} />
                              ),
                            )}
                          </S.PersonsWrapper>
                        </S.PersonsCell>
                        <S.PricePolicyCell>
                          <S.TabletHeading>
                            {t("profileOffer.netPrice")}
                          </S.TabletHeading>
                          <S.BoldHeadline>
                            <Price value={room.priceNet} />
                          </S.BoldHeadline>
                        </S.PricePolicyCell>
                        <S.ActionsCell>
                          <S.TabletHeading>
                            {t("profileOffer.clientPrice")}
                          </S.TabletHeading>
                          <FormAdapter
                            name={`prices.${offerItem.id}.${room.id}`}
                          >
                            {props => {
                              return (
                                <FormInput
                                  {...props}
                                  endAdornment={currency}
                                  adornmentStyle="filled"
                                  placeholder="Price"
                                />
                              );
                            }}
                          </FormAdapter>
                        </S.ActionsCell>
                      </S.RoomWrapper>
                    ))}
                    <S.RoomWrapper>
                      <S.NameCell>
                        <S.Heading>{t("profileOffer.meals")}</S.Heading>
                        <p>{offerItem.itemData.mealName.toLowerCase()}</p>
                      </S.NameCell>
                      <S.PersonsCell></S.PersonsCell>
                      <S.PricePolicyCell>
                        <S.Heading>
                          {t("profileOffer.cancelationPolicy")}
                        </S.Heading>
                        <StatusText
                          status={
                            offerItem.itemData.rateType === RateType.Refundable
                              ? StatusFlavour.Success
                              : StatusFlavour.Error
                          }
                        >
                          {offerItem.itemData.rateType === RateType.Refundable
                            ? t(
                                "searchHotelsBook.sidebar.cancelationPolicy.freeCancelationUntil",
                                {
                                  date: offerItem.itemData.cancellation
                                    ?.deadline,
                                },
                              )
                            : t(
                                "searchHotelsBook.sidebar.cancelationPolicy.nonRefundable",
                              )}
                        </StatusText>
                      </S.PricePolicyCell>
                      <S.ActionsCell>
                        <S.Heading>{t("profileOffer.actions")}</S.Heading>
                        <S.ActionsWrapper>
                          <Link
                            style={{ width: "100%" }}
                            target="_blank"
                            to={{
                              pathname: `/search/hotels/${offerItem.itemId}`,
                              search: getSearchParams(offerGroup, offerItem),
                            }}
                          >
                            <Button
                              variant="outlined"
                              fullWidth
                              style={{ justifyContent: "center" }}
                            >
                              {t("profileOffer.search")}
                            </Button>
                          </Link>
                          <ProfileOfferPanelDeleteRate
                            id={offerId}
                            offerItemId={offerItem.id}
                          />
                        </S.ActionsWrapper>
                      </S.ActionsCell>
                    </S.RoomWrapper>
                  </S.RatesWrapper>
                ))}
              </div>
            ))}
          </Panel>
        </div>
      ))}
    </>
  );
};

export default ProfileOfferPanel;
