import {
  ApiResponseSearchResultsPartial,
  PaginationOptions,
  PlaceType,
} from "@hotelspoint/types";

import FetchService from "./base/FetchService";

const ACTIVITY_API_PATH = "activities";

export interface Activity {
  id: number;
  name: string;
  summary: string;
  summaryFull: string;
  location: string;
  categories: string[];
  thumbnail: string;
  images: string[];
  provider: {
    brand: string;
    logo: string;
  };
  price: number;
  priceNet: number;
  // @note: The whole model is returned, skipping null values on purpose
}

export interface ActivityContent {
  name: string;
  mark?: string;
  text: string[];
}

export interface ActivitySearchPayload {
  placeId: number;
  placeType: PlaceType;
  dateIn: string;
  dateOut: string;
  adults: number;
  children: number[];
  useCache?: boolean;
}

export interface ActivitySearchResponse
  extends ApiResponseSearchResultsPartial<Activity> {
  // @todo: populate when doing the filtering
  filters: any;
}

export interface ActivityDetails {
  id: number;
  searchId: number;
  activity: Activity;
  content: ActivityContent[];
  languages: null; // @todo: add the correct typing? ActivityLanguage[]?
}

export interface ActivityOptionsParams {
  searchId: number;
  id: number;
  date: string;
}

export interface ActivityOptionPrice {
  key: number;
  name: string;
  sessions: string[];
  languages: string[];
  standalone: boolean;
  numTickets: number;
  price: number;
  priceNet: number;
}

export interface ActivityOptionQuestion {
  code: string;
  text: string;
  required: boolean;
}

export interface ActivityOption {
  key: number;
  name: string;
  prices: ActivityOptionPrice[];
  sessions: string[];
  languages: string[];
  remarks: string[];
  questions: ActivityOptionQuestion[];
  isPredefined: boolean;
  isRefundable: boolean;
  deadline: string;
}

export interface ActivitySession {
  code: string;
  name: string;
}

export interface ActivityLanguage {
  code: string;
  name: string;
}

export interface ActivityOptionsResponse {
  id: number;
  searchId: number;
  options: ActivityOption[];
  calendar: {
    [key: string]: number;
  };
  sessions: ActivitySession[];
  languages: ActivityLanguage[];
}

const ActivityService = {
  search: (payload: ActivitySearchPayload) => {
    return FetchService.post<ActivitySearchResponse>(
      `${ACTIVITY_API_PATH}/availability`,
      payload,
    );
  },
  getResultBySearchId: (searchId: number, params?: PaginationOptions) => {
    return FetchService.get<ActivitySearchResponse>(
      `${ACTIVITY_API_PATH}/availability/${searchId}/activities`,
      params,
    );
  },
  getDetails: (searchId: number, id: number) => {
    return FetchService.get<ActivityDetails>(
      `${ACTIVITY_API_PATH}/availability/${searchId}/activities/${id}`,
    );
  },
  getOptions: ({ searchId, id, date }: ActivityOptionsParams) => {
    return FetchService.get<ActivityOptionsResponse>(
      `${ACTIVITY_API_PATH}/availability/${searchId}/activities/${id}/options/${date}`,
    );
  },
};

export default ActivityService;
