import { MessageType } from "@hotelspoint/api";
import {
  Button,
  LinkExternal,
  Panel,
  ReservationInvoicesTable,
  ReservationMessages,
  ReservationPaymentsTable,
} from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import * as S from "./AdminReservation.styled";

interface AdminReservationParams extends Record<string, string> {
  id: string;
}

const AdminReservation = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("adminReservation.pageTitle"));

  const { id } = useParams<AdminReservationParams>();
  const reservationId = Number(id);

  if (!reservationId) {
    return <Navigate to="/404" replace={true} />;
  }

  return (
    <S.Wrapper>
      <Panel
        title={t("adminReservation.pageTitle")}
        actions={
          <LinkExternal to={`/profile/bookings/${reservationId}`}>
            <Button small variant="secondary">
              {t("adminReservation.actions.view")}
            </Button>
          </LinkExternal>
        }
      >
        <div>edit form here</div>
      </Panel>
      <ReservationPaymentsTable id={reservationId} />
      <ReservationInvoicesTable id={reservationId} />
      {Object.values(MessageType).map(type => (
        <ReservationMessages key={type} id={reservationId} type={type} />
      ))}
    </S.Wrapper>
  );
};

export default AdminReservation;
