import { handleError, useAgency, useUploadDocuments } from "@hotelspoint/api";
import {
  Box,
  Form,
  FormAdapter,
  FormUploadButton,
  Table,
  TableLayout,
} from "@hotelspoint/components";
import { useUserTokenStore } from "@hotelspoint/store";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import getColumns from "./AdminAgencyDocuments.columns";

interface AdminAgencyDocumentsProps {
  id: number;
}

const AdminAgencyDocuments = ({ id }: AdminAgencyDocumentsProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const token = useUserTokenStore(state => state.token);

  const [agency, isLoading] = useAgency(id);
  const [uploadDocuments, isUploadingDocuments] = useUploadDocuments();

  const data = useMemo(() => agency?.documents ?? [], [agency]);

  const columns = useMemo(() => {
    return getColumns({
      t,
      token: token as string,
    });
  }, [t, token]);

  const handleUpload = useCallback(
    async (file: File | null) => {
      if (!file) return;

      try {
        // @todo: unify approach to file uploads; use createFormData
        const formData = new FormData();
        formData.append("files[]", file);

        await uploadDocuments({
          payload: formData,
          params: {
            agencyId: id,
          },
        });

        // Invalidate the agency query cache
        queryClient.invalidateQueries({
          queryKey: ["agencies", id],
        });

        toast.success(t("toast.adminAgency.uploadFile"));
      } catch (error: any) {
        handleError({ t, error });
      }
    },
    [t, uploadDocuments, id, queryClient],
  );

  return (
    <TableLayout>
      <Table data={data} columns={columns} isLoading={isLoading} />
      <Form defaultValues={{ file: undefined }} validationSchema={undefined}>
        <Box width={1} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="file">
            {props => (
              <FormUploadButton
                {...props}
                onChange={handleUpload}
                isDisabled={isUploadingDocuments}
                buttonProps={{
                  small: true,
                }}
              />
            )}
          </FormAdapter>
        </Box>
      </Form>
    </TableLayout>
  );
};

export default AdminAgencyDocuments;
