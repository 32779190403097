import { ActivitySearchQuery } from "@hotelspoint/types";

import createRecentSearchesStore from "./utils/createRecentSearchesStore";

const PERSIST_NAME = "@hp::recent_activity_searches";

export interface RecentActivitySearchQuery extends ActivitySearchQuery {
  meta: {
    placeName: string;
  };
}

const useRecentActivitiesSearchesStore = createRecentSearchesStore<any>({
  persistName: PERSIST_NAME,
});

export default useRecentActivitiesSearchesStore;
