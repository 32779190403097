import { Container, HeaderImage } from "@hotelspoint/components";
import { useDocumentTitle } from "@hotelspoint/utils";
import { useTranslation } from "react-i18next";

import backgroundImage from "./assets/backgroundSearchForm.jpg";
import * as S from "./SearchTransfers.styled";
import SearchTransfersForm from "./SearchTransfersForm";
import SearchTransfersResults from "./SearchTransfersResults";
import useTransferSearchParams from "./useTransferSearchParams";
const SearchTransfers = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("searchTransfers.pageTitle"));

  const [query] = useTransferSearchParams();

  if (
    query.pickUp?.placeId !== undefined &&
    query.pickUp?.placeType !== undefined &&
    query.dropOff?.placeId !== undefined &&
    query.dropOff?.placeType !== undefined
  ) {
    return <SearchTransfersResults />;
  }

  return (
    <HeaderImage src={backgroundImage}>
      <Container>
        <S.HeaderWrapper>
          <S.HeaderCard>
            <SearchTransfersForm />
          </S.HeaderCard>
        </S.HeaderWrapper>
      </Container>
    </HeaderImage>
  );
};

export default SearchTransfers;
