import { useTranslation } from "react-i18next";

import FormCounter from "../../form/FormCounter";
import Price from "../../misc/Price";
import * as S from "./ActivityOptionPrice.styled";

export interface ActivityOptionPriceProps {
  name: string;
  price: number;
  value: number;
  isDisabled?: boolean;
  isStandalone?: boolean;
  onChange: (count: number) => void;
}

const ActivityOptionPrice = ({
  name,
  price,
  value,
  isDisabled = false,
  isStandalone = true,
  onChange,
}: ActivityOptionPriceProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <p>{name}</p>
        {!isStandalone && (
          <S.Description>
            {t("components.activityOptionPrice.standalone")}
          </S.Description>
        )}
      </S.ContentWrapper>
      <S.TicketPriceWrapper>
        <S.Price>
          <Price value={price} />
        </S.Price>
        <FormCounter
          id="tickets"
          name="tickets"
          value={value}
          disabled={{ decrement: isDisabled, increment: isDisabled }}
          onChange={onChange}
        />
      </S.TicketPriceWrapper>
    </S.Wrapper>
  );
};

export default ActivityOptionPrice;
