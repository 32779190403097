import {
  handleError,
  MessageStatus,
  useMessage,
  useUpdateMessage,
} from "@hotelspoint/api";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Button from "../../elements/Button";
import LinkExternal from "../../elements/LinkExternal";
import Dialog from "../Dialog";
import Modal from "../Modal";
import * as S from "./MessagesReadDialog.styled";

interface MessagesReadDialogProps {
  id: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const MessagesReadDialog = ({
  id,
  isOpen,
  setIsOpen,
}: MessagesReadDialogProps) => {
  const { t } = useTranslation();

  const [message, isLoading] = useMessage(id);
  const [updateMessage, isUpdating] = useUpdateMessage();

  const handleMarkAsUnread = async () => {
    try {
      await updateMessage({
        id,
        payload: {
          isNew: true,
        },
      });

      toast.success(t("toast.messages.updateNew"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  const handleSetAsPending = async () => {
    try {
      await updateMessage({
        id,
        payload: {
          status: MessageStatus.Pending,
        },
      });

      toast.success(t("toast.messages.updateStatus"));
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  if (!message || isLoading) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Modal title={message.subject} onClose={() => setIsOpen(false)}>
          <div style={{ width: 700 }}>
            <S.Content
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(message?.message),
              }}
            />
            <Modal.Actions>
              <S.ActionGroup>
                <Button
                  variant="outlined"
                  onClick={handleMarkAsUnread}
                  isDisabled={message.isNew}
                  isLoading={isUpdating}
                >
                  {t("components.messagesReadDialog.markAsUnread")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleSetAsPending}
                  isDisabled={message.status === MessageStatus.Pending}
                  isLoading={isUpdating}
                >
                  {t("components.messagesReadDialog.setAsPending")}
                </Button>
              </S.ActionGroup>
              <S.ActionGroup>
                <LinkExternal to={`/admin/bookings/${message.reservationId}`}>
                  <Button>
                    {t("components.messagesReadDialog.viewBooking")}
                  </Button>
                </LinkExternal>
                {/* @todo: respond modal */}
                <Button variant="secondary" onClick={() => {}}>
                  {t("components.messagesReadDialog.respond")}
                </Button>
              </S.ActionGroup>
            </Modal.Actions>
          </div>
        </Modal>
      </Dialog.Content>
    </Dialog>
  );
};

export default MessagesReadDialog;
