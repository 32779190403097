import {
  Box,
  Flex,
  FormAdapter,
  FormClearableInput,
  FormDatePickerSingle,
  FormTimePicker,
} from "@hotelspoint/components";
import { IconPlaneArrival, IconPlaneDeparture } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import * as S from "../SearchHotelBookForm/SearchHotelBookForm.styled";

interface FlightDetailsProps {
  isLoading?: boolean;
}

const FlightDetails = ({ isLoading }: FlightDetailsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <S.Divider />
      <S.SubTitle>
        {isLoading ? (
          <Skeleton />
        ) : (
          t("searchHotelsBook.content.transferFlightDetails")
        )}
      </S.SubTitle>
      <S.SectionTitle>
        <IconPlaneArrival size={18} />
        {isLoading ? (
          <S.FlexGrow>
            <Skeleton />
          </S.FlexGrow>
        ) : (
          <span>{t("searchHotelsBook.content.arrivalFlightDetails")}</span>
        )}
      </S.SectionTitle>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          {isLoading ? (
            <Skeleton height={40} />
          ) : (
            <FormAdapter
              name="arrivalFlightNumber"
              label={t(
                "searchHotelsBook.content.form.arrivalFlightNumber.label",
              )}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "searchHotelsBook.content.form.arrivalFlightNumber.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          {isLoading ? (
            <Skeleton height={40} />
          ) : (
            <FormAdapter
              name="arrivalFlightDate"
              label={t("searchHotelsBook.content.form.arrivalFlightDate.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "searchHotelsBook.content.form.arrivalFlightDate.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          {isLoading ? (
            <Skeleton height={40} />
          ) : (
            <FormAdapter
              name="arrivalFlightTime"
              label={t("searchHotelsBook.content.form.arrivalFlightTime.label")}
            >
              {props => (
                <FormTimePicker
                  {...props}
                  placeholder={t(
                    "searchHotelsBook.content.form.arrivalFlightTime.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
        </Box>
      </Flex>
      <S.SectionTitle>
        <IconPlaneDeparture size={18} />
        {isLoading ? (
          <S.FlexGrow>
            <Skeleton />
          </S.FlexGrow>
        ) : (
          <span>{t("searchHotelsBook.content.returnFlightDetails")}</span>
        )}
      </S.SectionTitle>
      <Flex mx={[0, 0, -1, -1]}>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          {isLoading ? (
            <Skeleton height={40} />
          ) : (
            <FormAdapter
              name="returnFlightNumber"
              label={t(
                "searchHotelsBook.content.form.returnFlightNumber.label",
              )}
            >
              {props => (
                <FormClearableInput
                  {...props}
                  placeholder={t(
                    "searchHotelsBook.content.form.returnFlightNumber.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          {isLoading ? (
            <Skeleton height={40} />
          ) : (
            <FormAdapter
              name="returnFlightDate"
              label={t("searchHotelsBook.content.form.returnFlightDate.label")}
            >
              {props => (
                <FormDatePickerSingle
                  {...props}
                  placeholder={t(
                    "searchHotelsBook.content.form.returnFlightDate.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={[0, 0, 1, 1]} py={1}>
          {isLoading ? (
            <Skeleton height={40} />
          ) : (
            <FormAdapter
              name="returnFlightTime"
              label={t("searchHotelsBook.content.form.returnFlightTime.label")}
            >
              {props => (
                <FormTimePicker
                  {...props}
                  placeholder={t(
                    "searchHotelsBook.content.form.returnFlightTime.placeholder",
                  )}
                />
              )}
            </FormAdapter>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default FlightDetails;
