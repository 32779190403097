import { TransferSearchPayload, TransferService } from "@hotelspoint/api";
import { useQuery } from "@tanstack/react-query";

const useTransferAvailabilitySearch = (params: TransferSearchPayload) => {
  const { data, isLoading } = useQuery({
    queryKey: ["transfers_availability", params],
    queryFn: () => TransferService.search(params),
  });

  const ret: [typeof data, boolean] = [data, isLoading];

  return ret;
};

export default useTransferAvailabilitySearch;
