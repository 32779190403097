import {
  ActivityOption as ActivityOptionAPI,
  ActivityOptionsResponse,
} from "@hotelspoint/api";
import {
  ActivityOption,
  ActivityOptionPrice,
  Box,
  Button,
  Form,
  FormAdapter,
  FormChildrenMethods,
  FormSelect,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { mediaQuery } from "@hotelspoint/theme";
import { useMediaQuery } from "@hotelspoint/utils";
import { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ActivityPriceDetails from "../ActivityPriceDetails";
import * as S from "./ActivityTicketsSection.styled";
import {
  defaultValues,
  FormValues,
  validationSchema,
} from "./ActivityTicketsSection.util";

interface ActivityTicketsSectionProps {
  options: ActivityOptionsResponse;
}

interface ActivityTicketsSectionInnerProps
  extends FormChildrenMethods<FormValues>,
    ActivityTicketsSectionProps {}

interface FilterOptions {
  code: string;
  name: string;
}

const mapFilters = (filters: FilterOptions[]) => {
  return filters.map(filter => ({
    label: filter.name,
    value: filter.code,
  }));
};

const filterOptions = (
  options: ActivityOptionAPI[],
  language: string | null,
  time: string | null,
) => {
  return options.filter(option => {
    const matchesLanguage =
      !language ||
      option.languages.includes(language) ||
      option.prices.some(price => price.languages.includes(language));

    const matchesSession =
      !time ||
      option.sessions.includes(time) ||
      option.prices.some(price => price.sessions.includes(time));

    return matchesLanguage && matchesSession;
  });
};

const ActivityTicketsSectionInner = ({
  options,
  setValue,
}: ActivityTicketsSectionInnerProps) => {
  const { t } = useTranslation();
  const isMobileL = useMediaQuery(mediaQuery.mobileL);

  const [sessions, languages] = useWatch({ name: ["sessions", "languages"] });

  const mappedSessions = useMemo(() => {
    return mapFilters(options.sessions);
  }, [options.sessions]);

  const mappedLanguages = useMemo(() => {
    return mapFilters(options.languages);
  }, [options.languages]);

  const [selectedOption, setSelectedOption] =
    useState<ActivityOptionAPI | null>(null);

  const filteredOptions = useMemo(() => {
    const data = filterOptions(options.options, languages, sessions);

    if (data.length === 1) {
      setSelectedOption(data[0]);
    } else {
      setSelectedOption(null);
    }
    return data;
  }, [options.options, sessions, languages]);

  const updateNumTickets = (priceKey: number, newNumTickets: number) => {
    setSelectedOption(prevValue => {
      if (prevValue === null) {
        return null;
      }
      return {
        ...prevValue,
        prices: prevValue.prices.map(price =>
          price.key === priceKey
            ? { ...price, numTickets: newNumTickets }
            : price,
        ),
      };
    });
  };

  const handleBook = () => {
    console.log("redirect to booking page");
  };

  useEffect(() => {
    if (mappedLanguages.length > 0 && !languages) {
      setValue("languages", mappedLanguages[0].value);
    }
    if (mappedSessions.length > 0 && !sessions) {
      setValue("sessions", mappedSessions[0].value);
    }
  }, [setValue, mappedLanguages, languages, mappedSessions, sessions]);

  return (
    <>
      {(mappedSessions.length !== 0 || mappedLanguages.length !== 0) && (
        <S.FilterWrapper>
          {mappedSessions.length !== 0 && (
            <Box width={[1, 1, 1 / 2, 1 / 4]}>
              <FormAdapter
                name="sessions"
                label={t("searchActivityResult.filters.sessions")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    options={mappedSessions}
                    isSearchable={false}
                  />
                )}
              </FormAdapter>
            </Box>
          )}
          {mappedLanguages.length !== 0 && (
            <Box width={[1, 1, 1 / 2, 1 / 4]}>
              <FormAdapter
                name="languages"
                label={t("searchActivityResult.filters.languages")}
              >
                {props => (
                  <FormSelect
                    {...props}
                    options={mappedLanguages}
                    isSearchable={false}
                  />
                )}
              </FormAdapter>
            </Box>
          )}
        </S.FilterWrapper>
      )}
      {filteredOptions.length === 0 ? (
        <StatusBlock status={StatusFlavour.Warning}>
          <h6>{t(`searchActivityResult.warning.title`)}</h6>
          <p>{t(`searchActivityResult.warning.description`)}</p>
        </StatusBlock>
      ) : (
        <>
          <S.Headline>{t("searchActivityResult.selectOption")}</S.Headline>
          <S.OptionsWrapper>
            {filteredOptions.length === 1 ? (
              <ActivityOption
                name={filteredOptions[0].name}
                value={true}
                isDisabled={true}
              />
            ) : (
              filteredOptions
                .filter(
                  option =>
                    selectedOption === null ||
                    selectedOption?.key === option.key,
                )
                .map(option => (
                  <ActivityOption
                    key={option.key}
                    name={option.name}
                    value={selectedOption?.key === option.key}
                    onChange={() =>
                      setSelectedOption(
                        (prevValue: ActivityOptionAPI | null) =>
                          prevValue === null ? option : null,
                      )
                    }
                  />
                ))
            )}
          </S.OptionsWrapper>
        </>
      )}
      {selectedOption && filteredOptions.length !== 0 && (
        <>
          <S.Headline>{t("searchActivityResult.numberOfTickets")}</S.Headline>
          <S.OptionsWrapper>
            {selectedOption.prices?.map(price => (
              <ActivityOptionPrice
                key={price.key}
                name={price.name}
                price={price.priceNet}
                value={price.numTickets}
                isDisabled={selectedOption.isPredefined}
                isStandalone={price.standalone}
                onChange={num => updateNumTickets(price.key, num)}
              />
            ))}
          </S.OptionsWrapper>
          <S.Footer>
            <ActivityPriceDetails option={selectedOption} />
            <Button
              fullWidth={isMobileL ? false : true}
              variant="tertiary"
              onClick={() => handleBook()}
            >
              {t("searchActivityResult.book")}
            </Button>
          </S.Footer>
        </>
      )}
    </>
  );
};

const ActivityTicketsSection = ({ options }: ActivityTicketsSectionProps) => {
  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema}>
      {formMethods => (
        <ActivityTicketsSectionInner {...formMethods} options={options} />
      )}
    </Form>
  );
};

export default ActivityTicketsSection;
