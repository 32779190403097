import { spacing } from "@hotelspoint/theme";
import { styled } from "styled-components";

export const ActivityListingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(2)}px;
  margin-top: ${spacing(3)}px;
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing(2)}px;
`;
