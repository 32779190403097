import { HotelSearchInfoResponse } from "@hotelspoint/api";
import { HotelRate } from "@hotelspoint/types";
import { formatDateTimeIso, setDateValues } from "@hotelspoint/utils";
import { array, InferType, mixed, number, object, string } from "yup";
import { boolean } from "yup";

import { FormType } from "./types";

const setDateTime = (date?: string, time?: string) => {
  if (!date || !time) {
    return undefined;
  }
  const [hours, minutes] = time.split(":");

  return formatDateTimeIso(
    setDateValues(date, {
      hours: Number(hours),
      minutes: Number(minutes),
    }),
  );
};

const personSchema = object({
  title: string().required(
    "searchHotelsBook.content.form.title.validation.required",
  ),
  name: string().required(
    "searchHotelsBook.content.form.firstName.validation.required",
  ),
  surname: string().required(
    "searchHotelsBook.content.form.lastName.validation.required",
  ),
  age: number(),
}).required();

const requiredIfHasTransfers = (message: string) =>
  string().when("hasTransfers", ([hasTransfers], schema) => {
    return hasTransfers ? schema.required(message) : schema.notRequired();
  });

export const defaultValues = {
  type: FormType.AllPassengers,
  guests: [],
  leader: {},
  children: [],
  // Read-only
  hasTransfers: false,
  arrivalFlightNumber: "",
  arrivalFlightDate: undefined,
  arrivalFlightTime: "",
  returnFlightNumber: "",
  returnFlightDate: undefined,
  returnFlightTime: "",
};

export const validationSchema = object()
  .shape({
    type: mixed<FormType>().oneOf(
      Object.values(FormType).map(e => e as FormType),
    ),
    guests: array().when("type", ([type], schema) => {
      return type === FormType.AllPassengers
        ? schema.of(array().of(personSchema)).required()
        : schema.notRequired();
    }),
    children: array().when("type", ([type], schema) => {
      return type === FormType.AllPassengers
        ? schema.of(array().of(personSchema))
        : schema.notRequired();
    }),
    leader: object().when("type", ([type], schema) => {
      return type === FormType.LeaderOnly
        ? personSchema.required()
        : schema.notRequired();
    }),
    // Read-only
    hasTransfers: boolean(),
    arrivalFlightNumber: requiredIfHasTransfers(
      "searchHotelsBook.content.form.arrivalFlightNumber.validation.required",
    ),
    arrivalFlightDate: requiredIfHasTransfers(
      "searchHotelsBook.content.form.arrivalFlightDate.validation.required",
    ),
    arrivalFlightTime: requiredIfHasTransfers(
      "searchHotelsBook.content.form.arrivalFlightTime.validation.required",
    ),
    returnFlightNumber: requiredIfHasTransfers(
      "searchHotelsBook.content.form.returnFlightNumber.validation.required",
    ),
    returnFlightDate: requiredIfHasTransfers(
      "searchHotelsBook.content.form.returnFlightDate.validation.required",
    ),
    returnFlightTime: requiredIfHasTransfers(
      "searchHotelsBook.content.form.returnFlightTime.validation.required",
    ),
  })
  .required();

export type FormValues = InferType<typeof validationSchema>;

export const form2Entity = (values: FormValues) => {
  const flightDetails = values.hasTransfers
    ? {
        flightDetails: {
          arrivalFlightNumber: values.arrivalFlightNumber,
          arrivalFlightDate: setDateTime(
            values.arrivalFlightDate,
            values.arrivalFlightTime,
          ),
          returnFlightNumber: values.returnFlightNumber,
          returnFlightDate: setDateTime(
            values.returnFlightDate,
            values.returnFlightTime,
          ),
        },
      }
    : {};

  if (values.type === FormType.LeaderOnly) {
    return {
      names: [values.leader],
      ...flightDetails,
    };
  }
  if (values.type === FormType.AllPassengers && values.guests) {
    return {
      names: values.guests.map((adults, roomId) => ({
        roomId,
        adults,
        children: values.children?.[roomId] || [],
      })),
      ...flightDetails,
    };
  }
  return [];
};

export const entity2Form = (
  hotelRate: HotelRate,
  searchInfo: HotelSearchInfoResponse,
): FormValues => {
  return {
    type: FormType.AllPassengers,
    guests: [],
    leader: {},
    children: hotelRate.rooms.map((room: any) =>
      room.children.map((child: any) => {
        return {
          age: child,
        };
      }),
    ),
    hasTransfers: hotelRate.hasTransfers,
    arrivalFlightNumber: "",
    arrivalFlightDate: searchInfo.search.checkIn,
    arrivalFlightTime: "",
    returnFlightNumber: "",
    returnFlightDate: searchInfo.search.checkOut,
    returnFlightTime: "",
  };
};
