import {
  handleError,
  HotelRateResponse,
  ReservationCreatePayload,
  useCreateReservation,
  useHotelSearchInfo,
} from "@hotelspoint/api";
import {
  Box,
  Button,
  ConfirmDialog,
  Flex,
  Form,
  FormAdapter,
  FormContext,
  FormLayout,
  FormRadio,
  MarkupPrice,
  Price,
  StatusBlock,
  StatusFlavour,
} from "@hotelspoint/components";
import { ReservationType } from "@hotelspoint/types";
import { IconManFilled, IconUserFilled } from "@tabler/icons-react";
import sumBy from "lodash/sumBy";
import { useMemo, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { totalPrice, totalPriceNet } from "../../SearchHotelBook.util";
import useSearchHotelBookParams from "../../useSearchHotelBookParams";
import FlightDetails from "../FlightDetails";
import ImportantInfo from "../ImportantInfo";
import PassengerInfo from "../PassengerInfo";
import { PassengerType } from "../PassengerInfo/PassengerInfo";
import * as S from "./SearchHotelBookForm.styled";
import {
  defaultValues,
  entity2Form,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchHotelBookForm.util";
import { FormType } from "./types";

interface SearchHotelBookParams extends Record<string, string> {
  id: string;
}

interface SearchHotelBookFormProps {
  hotelRate?: HotelRateResponse;
  isLoading: boolean;
}

interface SearchHotelBookFormInner extends SearchHotelBookFormProps {}

const SearchHotelBookFormInner = ({
  hotelRate,
  isLoading,
}: SearchHotelBookFormInner) => {
  const { t } = useTranslation();

  const { id } = useParams<SearchHotelBookParams>();

  const hotelId = Number(id);
  const [{ searchId, rateId }] = useSearchHotelBookParams();

  const [createReservation] = useCreateReservation();

  const namesRef = useRef<any>([]);
  const dialogTriggerRef = useRef<HTMLDivElement>(null);
  const hasTransfers = useMemo(() => hotelRate?.hasTransfers, [hotelRate]);

  const [formType] = useWatch({ name: ["type"] });

  const onSubmit = (formValues: FormValues) => {
    const bookData = form2Entity(formValues);
    namesRef.current = bookData;
    dialogTriggerRef.current?.click();
  };

  const handleBooking = async () => {
    const payload: ReservationCreatePayload = {
      type: ReservationType.Hotel,
      rateId: rateId as number,
      hotelId: hotelId,
      searchId: searchId as number,
      names: namesRef.current.names,
      ...(hasTransfers && namesRef.current.flightDetails),
    };

    try {
      const response = await createReservation(payload);

      console.log(response.status);
      // redirect
    } catch (error: any) {
      handleError({ t, error });
    }
  };

  return (
    <>
      <ConfirmDialog
        title={t("searchHotelsBook.content.actions.book.modal.title")}
        description={t(
          "searchHotelsBook.content.actions.book.modal.description",
        )}
        onConfirm={handleBooking}
        isLoading={false}
      >
        <div ref={dialogTriggerRef} />
      </ConfirmDialog>
      <FormLayout>
        <S.RadioGroup>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Flex alignItems={"center"}>
              <Box width={[2 / 14]}>
                <p>{t("searchHotelsBook.content.radioGroup.type")}</p>
              </Box>
              <Box width={[12 / 14]}>
                <FormAdapter name="type">
                  {props => (
                    <Flex alignItems={"center"}>
                      <Box width={[6 / 12]}>
                        <FormRadio
                          {...props}
                          id={FormType.LeaderOnly}
                          value={FormType.LeaderOnly}
                          label={t(
                            "searchHotelsBook.content.radioGroup.leaderOnly",
                          )}
                          checked={formType === FormType.LeaderOnly}
                        />
                      </Box>
                      <Box width={[6 / 12]} px={[0, 0, 1, 1]}>
                        <FormRadio
                          {...props}
                          id={FormType.AllPassengers}
                          value={FormType.AllPassengers}
                          label={t(
                            "searchHotelsBook.content.radioGroup.allPassengers",
                          )}
                          checked={formType === FormType.AllPassengers}
                        />
                      </Box>
                    </Flex>
                  )}
                </FormAdapter>
              </Box>
            </Flex>
          )}
        </S.RadioGroup>
        {isLoading ? (
          <>
            <S.FlexWrapper>
              <S.FlexGrow>
                <S.RoomName>
                  <Skeleton />
                </S.RoomName>
              </S.FlexGrow>
              <S.FlexGrow>
                <S.Price>
                  <Skeleton />
                </S.Price>
              </S.FlexGrow>
            </S.FlexWrapper>
            <S.SectionTitle>
              <IconUserFilled size={18} />
              <S.FlexGrow>
                <Skeleton />
              </S.FlexGrow>
            </S.SectionTitle>
            <PassengerInfo isLoading={isLoading} />
          </>
        ) : formType === FormType.LeaderOnly ? (
          <PassengerInfo passengerIndex={0} name="leader" />
        ) : (
          hotelRate?.rooms.map((room, roomIndex) => (
            <div key={roomIndex}>
              <S.FlexWrapper>
                <S.RoomName>{room.roomName}</S.RoomName>
                <S.Price>
                  <Price value={room.price} />
                </S.Price>
              </S.FlexWrapper>
              {room.adults !== 0 && (
                <S.SectionTitle>
                  <IconUserFilled size={18} />
                  <span>
                    {t("searchHotelsBook.content.adult", {
                      count: room.adults,
                    })}
                  </span>
                </S.SectionTitle>
              )}
              {Array.from({ length: room.adults }).map((_, adultIndex) => {
                const name = `guests.${room.id}.${adultIndex}`;
                return (
                  <PassengerInfo
                    key={adultIndex}
                    name={name}
                    passengerIndex={adultIndex}
                  />
                );
              })}
              {room.children.length !== 0 && (
                <S.SectionTitle>
                  <IconManFilled size={18} />
                  <span>{t("searchHotelsBook.content.children")}</span>
                </S.SectionTitle>
              )}
              {room.children.map((_, childIndex) => {
                const name = `children.${room.id}.${childIndex}`;
                return (
                  <PassengerInfo
                    key={childIndex}
                    name={name}
                    type={PassengerType.Children}
                    passengerIndex={childIndex}
                  />
                );
              })}
              {room.promotions && (
                <S.StatusBlockWrapper>
                  <StatusBlock status={StatusFlavour.Info}>
                    <S.StatusBlockHeader>
                      {t("searchHotelsBook.content.promoOffer")}
                    </S.StatusBlockHeader>
                    {Array.isArray(room.promotions) &&
                      room.promotions.map((promotion, promotionIndex) => (
                        <S.StatusBlockCopy key={promotionIndex}>
                          {promotion.name}
                          {promotion.amount && (
                            <>
                              : <Price value={promotion.amount} />
                            </>
                          )}
                        </S.StatusBlockCopy>
                      ))}
                  </StatusBlock>
                </S.StatusBlockWrapper>
              )}
            </div>
          ))
        )}
        {hasTransfers && <FlightDetails isLoading={isLoading} />}
        <S.Divider />
        <ImportantInfo hotelRate={hotelRate} isLoading={isLoading} />
        <S.FormFooter>
          {isLoading ? (
            <S.FlexGrow>
              <Skeleton />
            </S.FlexGrow>
          ) : (
            <S.FormPriceWrap>
              <p>{t("searchHotelsBook.content.totalPrice")}</p>
              <MarkupPrice
                value={{
                  total: totalPrice(hotelRate),
                  net: totalPriceNet(hotelRate),
                }}
              >
                <S.PriceTotal>
                  <Price value={sumBy(hotelRate?.rooms, "price")} />
                </S.PriceTotal>
              </MarkupPrice>
            </S.FormPriceWrap>
          )}

          <FormContext<any>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchHotelsBook.content.form.submit")}
              </Button>
            )}
          />
        </S.FormFooter>
      </FormLayout>
    </>
  );
};

const SearchHotelBookForm = ({
  hotelRate,
  isLoading,
}: SearchHotelBookFormProps) => {
  const [{ searchId }] = useSearchHotelBookParams();
  const [searchInfo, isLoadingSearchInfo] = useHotelSearchInfo(
    searchId as number,
  );
  const formValues = useMemo(() => {
    if (!hotelRate || !searchInfo) return defaultValues;
    return entity2Form(hotelRate, searchInfo);
  }, [hotelRate, searchInfo]);

  return (
    <Form
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => (
        <SearchHotelBookFormInner
          hotelRate={hotelRate}
          isLoading={isLoading || isLoadingSearchInfo}
          {...formMethods}
        />
      )}
    </Form>
  );
};

export default SearchHotelBookForm;
