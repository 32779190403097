import { usePlaces } from "@hotelspoint/api";
import {
  Box,
  Button,
  Flex,
  Form,
  FormAdapter,
  FormAutoComplete,
  FormAutoCompleteOption,
  FormChildrenMethods,
  FormContext,
  FormDatePickerRange,
  FormLayout,
  FormPersonSelector,
  OptionGroup,
  PlaceTypeIcon,
} from "@hotelspoint/components";
import { useRecentActivitiesSearchesStore } from "@hotelspoint/store";
import { PlaceType } from "@hotelspoint/types";
import { getPlaceTypeName } from "@hotelspoint/utils";
import flatten from "lodash/flatten";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { PERSON_SELECTOR_CONFIG, PLACE_TYPE_ORDER } from "../constants";
import useActivitySearchParams from "../useActivitySearchParams";
import {
  defaultValues,
  form2Entity,
  FormValues,
  validationSchema,
} from "./SearchActivitiesForm.form";
import * as S from "./SearchActivitiesForm.styled";

type PlaceOption = Required<FormAutoCompleteOption<{ type: PlaceType }>>;

const SearchActivitiesFormInner = ({
  setValue,
}: FormChildrenMethods<FormValues>) => {
  const { t } = useTranslation();

  const [, setQuery] = useActivitySearchParams();

  const dateToday = useMemo(() => new Date(), []);
  const currentYear = useMemo(() => dateToday.getFullYear(), [dateToday]);

  const [search] = useWatch({
    name: ["search"],
  });

  const [debouncedSearch] = useDebounce(search, 350);

  const [places, isLoadingPlaces] = usePlaces({
    types: [PlaceType.Area, PlaceType.Destination, PlaceType.City],
    search: debouncedSearch ?? "",
  });

  const recentSearchesStore = useRecentActivitiesSearchesStore(state => state);

  const placeGroupOptions: OptionGroup<PlaceOption>[] = useMemo(() => {
    if (!places || isLoadingPlaces) return [];

    const groups = Object.entries(places)
      .map(([type, places]) => {
        return {
          label: t(getPlaceTypeName(type as PlaceType)),
          value: type as PlaceType,
          options: places?.map(p => ({
            value: p.id,
            label: p.name,
            meta: {
              type: type as PlaceType,
            },
          })),
        };
      })
      .filter(group => group.options.length > 0);

    return groups.sort((a, b) => {
      return (
        PLACE_TYPE_ORDER.indexOf(a.value) - PLACE_TYPE_ORDER.indexOf(b.value)
      );
    });
  }, [t, places, isLoadingPlaces]);

  const placeOptionsFlat = useMemo(() => {
    return flatten(placeGroupOptions.map(group => group.options));
  }, [placeGroupOptions]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      const payload = form2Entity(formValues);

      const newQuery = {
        ...payload,
        useCache: false,
      };

      // Find the place option and set the name inside the meta
      const placeOption = placeOptionsFlat.find(
        p => p?.value === payload.placeId,
      );

      // Populate the recent searches store
      if (placeOption) {
        const queryAttributesToSave = newQuery;

        recentSearchesStore.add({
          ...queryAttributesToSave,
          meta: {
            placeName: placeOption?.label as string,
          },
        });
      }

      setQuery(payload);
    },
    [placeOptionsFlat, recentSearchesStore, setQuery],
  );

  const handleSelectPlace = useCallback(
    (option: any) => {
      if (option) {
        setValue("place", {
          id: option.value,
          type: option.meta.type,
        });
      } else {
        setValue("place", {});
      }
    },
    [setValue],
  );

  return (
    <FormLayout>
      <Flex mx={[0, 0, -1, -1]} justifyContent={"flex-end"}>
        <Box width={1} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="search" label={t("searchActivities.search.label")}>
            {props => (
              <FormAutoComplete
                {...props}
                placeholder={t("searchActivities.search.placeholder")}
                options={placeGroupOptions}
                isLoading={isLoadingPlaces}
                renderOption={option => (
                  <S.PlaceOption key={option.value}>
                    <PlaceTypeIcon
                      type={option.meta.type}
                      iconProps={{ size: 22 }}
                    />
                    <span>{option.label}</span>
                  </S.PlaceOption>
                )}
                onSelect={handleSelectPlace}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter name="dates" label={t("searchActivities.dates.label")}>
            {props => (
              <FormDatePickerRange
                {...props}
                placeholder={t("searchActivities.dates.placeholder")}
                dayPickerProps={{
                  disabled: [{ before: dateToday }],
                  defaultMonth: new Date(currentYear, dateToday.getMonth()),
                  fromYear: currentYear,
                }}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormAdapter
            name="travelers"
            label={t("searchActivities.travelers.label")}
          >
            {props => (
              <FormPersonSelector
                {...props}
                placeholder={t("searchActivities.travelers.placeholder")}
                config={PERSON_SELECTOR_CONFIG}
              />
            )}
          </FormAdapter>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 2]} px={[0, 0, 1, 1]} py={1}>
          <FormContext<FormValues>
            render={({ handleSubmit }) => (
              <Button
                type="submit"
                variant="secondary"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                {t("searchActivities.submit")}
              </Button>
            )}
          />
        </Box>
      </Flex>
    </FormLayout>
  );
};

interface SearchActivitiesFormProps {
  initialValues?: Partial<FormValues>;
}

const SearchActivitiesForm = ({
  initialValues = {},
}: SearchActivitiesFormProps) => {
  // Load initial values from the outside - outdated recent search queries without dates
  const formValues = useMemo(
    () => ({
      ...defaultValues,
      ...initialValues,
    }),
    [initialValues],
  );

  return (
    <Form<FormValues>
      defaultValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formMethods => <SearchActivitiesFormInner {...formMethods} />}
    </Form>
  );
};

export default SearchActivitiesForm;
